import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchStatusData } from "../services/statusServices";
import { useProfile } from "./ProfileContext";

const StatusContext = createContext();

export const useStatus = () => {
  return useContext(StatusContext);
};

export const StatusProvider = ({ children }) => {
  const [statusData, setStatusData] = useState(null);
  const { token } = useProfile();

  useEffect(() => {
    if (token) {
      const getStatusData = async () => {
        try {
          const data = await fetchStatusData(token);
          setStatusData(data);
        } catch (error) {
          console.error("Failed to fetch status data:", error);
        }
      };
      getStatusData();
    }
  }, [token]);

  return (
    <StatusContext.Provider value={{ statusData }}>
      {children}
    </StatusContext.Provider>
  );
};
