export const fetchStatusData = async (token) => {
  try {
    const response = await fetch("https://gollowers.fr.to/stats", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};


