import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import HomePage from "./components/HomePage";
// import ProfilePage from "./components/Profile/ProfilePage";
// import Navbar from "./components/Navbar/Navbar";
// import Footer from "./components/Footer/Footer";
import { ProfileProvider } from "./context/ProfileContext";
import { StatusProvider } from "./context/StatusContext";
import { AuthProvider } from "./context/AuthContext"; // Ensure this import is correct

const HomePage = React.lazy(() => import("./components/HomePage"));
const Navbar = React.lazy(() => import("./components/Navbar/Navbar"));
const Footer = React.lazy(() => import("./components/Footer/Footer"));
const ProfilePage = React.lazy(() =>
  import("./components/Profile/ProfilePage")
);

function App() {
  return (
    <Router>
      <AuthProvider>
        <Navbar />
        <main>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route
                path="/profile/*"
                element={
                  <ProfileProvider>
                    <StatusProvider>
                      <ProfilePage />
                    </StatusProvider>
                  </ProfileProvider>
                }
              />
            </Routes>
          </Suspense>
        </main>
        <Footer />
      </AuthProvider>
    </Router>
  );
}

export default App;
