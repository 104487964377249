export const fetchProfileData = async (token) => {
  try {
    const response = await fetch("https://gollowers.fr.to/my-profile", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 500) {
      // Log out user by clearing the token
      console.error("Server error 500, logging out...");

      localStorage.removeItem("token");
      return null; 
    }

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error.message);
    throw error;
  }
};
