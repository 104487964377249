import React, { createContext, useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { fetchProfileData } from "../services/profileService";
import { useNavigate } from "react-router-dom";

const ProfileContext = createContext();

export const useProfile = () => {
  return useContext(ProfileContext);
};

export const ProfileProvider = ({ children }) => {
  const [profileData, setProfileData] = useState(null);
  const [searchParams] = useSearchParams();
  const [token, setToken] = useState(() => localStorage.getItem("token"));
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  // useEffect(() => {
  //   const queryToken = searchParams.get("token");
  //   if (queryToken) {
  //     localStorage.setItem("token", queryToken);
  //     setToken(queryToken);
  //     navigate("/profile");
  //   }
  // }, [searchParams, navigate]);

  // useEffect(() => {
  //   const fetchProfile = async () => {
  //     if (token) {
  //       try {
  //         const data = await fetchProfileData(token);
  //         setProfileData(data);
  //       } catch (error) {
  //         console.error("Failed to fetch profile data:", error);
  //         setProfileData(null);
  //       } finally {
  //         setTimeout(() => {
  //           setLoading(false);
  //         }, 1000);
  //       }
  //     } else {
  //       setProfileData(null);
  //       setTimeout(() => {
  //         setLoading(false);
  //       }, 1000);
  //     }
  //   };

  //   fetchProfile();
  // }, [token, navigate]);

  useEffect(() => {
    const queryToken = searchParams.get("token");
    if (queryToken) {
      localStorage.setItem("token", queryToken);
      setToken(queryToken);
      // navigate("/profile", { replace: true });
    }
  }, [searchParams, navigate]);

  useEffect(() => {
    const fetchProfile = async () => {
      setLoading(true); 

      if (token) {
        try {
          const data = await fetchProfileData(token);
          setProfileData(data);
        } catch (error) {
          console.error("Failed to fetch profile data:", error);
          setProfileData(null);
        } finally {
          setLoading(false); 
        }
      } else {
        setProfileData(null);
        setLoading(false); 
      }
    };

    if (token) {
      fetchProfile();
    }
  }, [token]);

  return (
    <ProfileContext.Provider value={{ profileData, token, setToken, loading }}>
      {children}
    </ProfileContext.Provider>
  );
};
